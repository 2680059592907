<template>
  <div class="works-container">
    <div class="conditions">
      <el-select v-model="currentPeriod" @change="changePeriod">
        <el-option
          v-for="item in periods"
          :key="item.id"
          :label="'第' + item.num + '期'"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="currentType"
        style="margin-left: 50px"
        @change="changeType"
      >
        <el-option
          v-for="item in typeOptions"
          :key="item.label"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </div>
    <el-table :data="list" stripe border fit>
      <el-table-column prop="id" label="序号"></el-table-column>
      <el-table-column prop="name" label="姓名"></el-table-column>
      <el-table-column prop="works_name" label="作品名称"></el-table-column>
      <el-table-column label="作品">
        <template #default="scope">
          <el-image
            v-if="scope.row.works_type === 0"
            :src="scope.row.works_url"
            style="width: 100px; height: 100px"
            fit="cover"
            :preview-src-list="imageList"
          ></el-image>
          <span v-else>
            <a
              :href="scope.row.works_url"
              style="color: #2c67d5; text-decoration: #2c67d5"
              target="_blank"
              >查看文件</a
            >
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="联系方式"></el-table-column>
      <el-table-column prop="school" label="学校"></el-table-column>
      <el-table-column prop="Result.rank" label="排名" sortable>
        <template #default="scope">
          <div
            style="
              display: flex;
              width: 100%;
              flex-direction: column;
              align-items: center;
            "
          >
            <span>{{
              scope.row.Result?.rank ? scope.row.Result.rank : '无'
            }}</span>
            <!--            <el-button-->
            <!--              type="text"-->
            <!--              v-if="scope.row.period_id === onlinePeriod"-->
            <!--              @click="toRank(scope.row)"-->
            <!--              >设置</el-button-->
            <!--            >-->
          </div>
        </template>
      </el-table-column>
      <!--      <el-table-column prop="sn" label="作品识别码"></el-table-column>-->
    </el-table>
    <el-pagination
      :total="total"
      background
      layout="prev, pager, next"
      :current-page="currentPage"
      style="margin-top: 20px"
      @current-change="getNewData"
    ></el-pagination>
    <!--    <el-dialog v-model="rankVisible" :width="400" title="设置排名">-->
    <!--      <el-input v-model="newRank"></el-input>-->
    <!--      <el-button-->
    <!--        @click="setRank"-->
    <!--        style="margin: 20px auto 0"-->
    <!--        :loading="rankLoading"-->
    <!--        >确认</el-button-->
    <!--      >-->
    <!--    </el-dialog>-->
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { resultList, periodList, createRank } from '@/api'
import { myError, mySuccess } from '@/hooks/useMessage'

const currentType = ref(0)
const typeOptions = [
  {
    label: '图片',
    value: 0
  },
  {
    label: '文件',
    value: 1
  }
]

const currentPeriod = ref(0)
const onlinePeriod = ref(0)
const periods = ref([])
onMounted(() => {
  periodList().then((res) => {
    periods.value = res.data
    const current = res.data.find((item) => item.is_current)
    currentPeriod.value = onlinePeriod.value = current.id
    getData(1, current.id, currentType.value)
  })
})

const list = ref([])
const imageList = ref([])
const total = ref(0)
const currentPage = ref(1)

const getData = (page, period_id = 1, type) => {
  resultList(page, period_id, type).then((res) => {
    // console.log(res)
    list.value = res.data.rows
    total.value = res.data.count
    res.data.rows.forEach((item) => {
      if (item.works_type === 0) imageList.value.push(item.works_url)
    })
  })
}

const getNewData = () => {
  getData(currentPage.value, currentPeriod.value, currentType.value)
}

const changePeriod = (e) => {
  console.log(e)
  currentPage.value = 1
  getData(1, e, currentType.value)
}

const changeType = (e) => {
  currentPage.value = 1
  getData(1, currentPeriod.value, e)
}

const rankObj = ref({})
const rankVisible = ref(false)
const newRank = ref(1)
const rankLoading = ref(false)
const toRank = (row) => {
  rankVisible.value = true
  rankObj.value = row
}
const setRank = () => {
  if (!newRank.value) return
  const params = {
    id: rankObj.value.Result?.id,
    works_id: rankObj.value.id,
    works_type: rankObj.value.works_type,
    rank: parseInt(newRank.value),
    period_id: rankObj.value.period_id
  }
  console.log(params)
  rankLoading.value = true
  createRank(params)
    .then((res) => {
      if (res.code === 1000) {
        mySuccess()
        getData(currentPage.value, currentPeriod.value, currentType.value)
        rankVisible.value = false
      } else {
        myError()
      }
    })
    .catch((err) => {
      myError()
    })
    .finally(() => {
      rankLoading.value = false
    })
}
</script>

<style scoped lang="scss">
.works-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .conditions {
    width: 100%;
    margin-bottom: 30px;
  }
}
</style>
